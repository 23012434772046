"use client"

import { createContext, useContext, useEffect, useState } from "react"
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api"
import { useSession } from "next-auth/react"
import CommunityCheckPopUp from "@/app/components/ui-components/CommunityCheckPopUp"
import { AUTHENTICATED, USERTYPE_ADMINISTRATOR, USERTYPE_GUEST, USERTYPE_MODERATOR, VERIFICATION_STATUS_NEW_USER, VERIFICATION_STATUS_NOT_VERIFIED } from "@/lib/constants"
import { getCommunityById } from "@/db/community"


const GUEST_USER = GRAPHQL_AUTH_MODE.API_KEY;
const COGNITO_USER = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;

export const UserDetailContext = createContext({});

export default function UserDetailProvider({ children }) {
  const [userData, setUserData] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false)
  const [isModerator, setIsModerator] = useState(false)
  const [refresh, setRefresh] = useState({ value: false })
  const [askForDetails, setAskForDetails] = useState(false)
  const [authStatus, setAuthStatus] = useState(USERTYPE_GUEST)
  const [isEditMode, setIsEditMode] = useState(false)
  const [userAuthMode, setUserAuthMode] = useState(GUEST_USER)
  const [showCommunityCheckPop, setShowCommunityCheckPop] = useState(false)
  const [showNoLoginPopUp, setShowNoLoginPopup] = useState(false)

  const session = useSession()
  const user = session?.data?.user

  useEffect(() => {
    const setupUserData = async () => {
      if (session?.status === AUTHENTICATED && session?.data?.user) {
        const sessionUser = session.data.user;

        if (sessionUser.communityId) {
          try {
            const communityData = await getCommunityById(sessionUser.communityId);
            setUserData({ ...sessionUser, community: communityData });
          } catch (error) {
            console.error('Error fetching community:', error);
            setUserData(sessionUser);
          }
        } else {
          setUserData(sessionUser);
        }

        setIsAdmin(sessionUser.userType === USERTYPE_ADMINISTRATOR)
        setIsModerator(sessionUser.userType === USERTYPE_MODERATOR)
        if (sessionUser.verificationStatus == VERIFICATION_STATUS_NEW_USER) {
          setAskForDetails(true)
        } else {
          setAskForDetails(false)
        }
        setIsEditMode(false)
        setAuthStatus(AUTHENTICATED)
      } else {
        setUserData(null)
        setIsAdmin(false)
        setIsModerator(false)
        setAskForDetails(false)
        setIsEditMode(false)
        setAuthStatus(USERTYPE_GUEST)
      }
    };

    setupUserData();
  }, [session]);

  const checkIfAllowedByCommunity = (operation) => {
    if (session?.status === AUTHENTICATED) {
      if (operation !== undefined) {
        operation()
      }
    } else {
      setShowCommunityCheckPop(true)
    }
  }

  const loggedInUserOperationOnly = (operation) => {
    if (session?.status !== AUTHENTICATED) {
      setShowNoLoginPopup(true)
    } else {
      if (operation !== undefined) {
        operation()
      }
    }
  }

  return (
    <UserDetailContext.Provider
      value={{
        userData,
        setRefresh,
        refresh,
        isEditMode,
        setIsEditMode,
        isModerator,
        isAdmin,
        user,
        askForDetails,
        setAskForDetails,
        authStatus,
        userAuthMode,
        loggedInUserOperationOnly,
        setUserData
      }}
    >

      {children}
      {
        authStatus === AUTHENTICATED
          ? <CommunityCheckPopUp showModal={showCommunityCheckPop} setShowModal={setShowCommunityCheckPop} setRefresh={setRefresh} />
          : <CommunityCheckPopUp showModal={showNoLoginPopUp} setShowModal={setShowNoLoginPopup} setRefresh={setRefresh} showRequireLoginMessage={true} />

      }
    </UserDetailContext.Provider>
  );
}
export const useUserData = () => useContext(UserDetailContext);
