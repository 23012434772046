import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useState } from "react";

export default function AlertModal({ openModal, closeModal, text, buttons }) {
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    closeModal(false);
  };

  useEffect(() => {
    if (openModal) setOpen(openModal);
  }, [openModal]);

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // PaperProps={{
        //   sx: { position: "fixed", top: "30%", m: "auto" },
        // }}
      >
        <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
        <DialogContent>
          <DialogContentText component={"div"} id="alert-dialog-description">
            {text}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          {buttons}
        </DialogActions>
      </Dialog>
    </div>
  );
}
