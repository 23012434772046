import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import { DB_ERROR, DB_SUCCESS, DB_WARNING, ERROR } from "@/lib/constants";

import { createContext, useContext, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
// setOpenEdit(false);

export const NotifyContext = createContext({});
const DEV_ENV = "development";

export default function NotificationProvider({ children }) {
  const { authStatus } = useAuthenticator((context) => [context.route]);

  const [notificationDetails, setNotificationDetails] = useState({
    isVisible: false,
    severity: "success",
    message: "",
  });
  const isDevelopmentEnv = process.env.APP_SERVE_MODE === DEV_ENV;

  const notify = ({ type, message, error = null }) => {
    /*
    console.log(
      "Notification is running.",
      error,
      Array.isArray(error?.errors)
    );
    */
    let errorMessage;
    if (error && Array.isArray(error?.errors)) {
      errorMessage = error?.errors[0]?.message;
    } else {
      errorMessage = error?.message;
    }
    const details = {
      isVisible: true,
      message: isDevelopmentEnv && errorMessage ? errorMessage : message,
    };
    switch (type) {
      case ERROR:
        setNotificationDetails({ ...details, severity: ERROR });
        break;
      case DB_ERROR:
        setNotificationDetails({ ...details, severity: ERROR });
        break;
      case DB_SUCCESS:
        setNotificationDetails({ ...details, severity: DB_SUCCESS });
        break;

      case DB_WARNING:
        setNotificationDetails({ ...details, severity: DB_WARNING, message: message });
        break;

      default:
        break;
    }
  };

  /*
  useEffect(() => {
    console.log("notification change", notificationDetails);
  }, [notificationDetails]);
  */

  const topCenter = { vertical: "top", horizontal: "center" };
  const topRight = { vertical: "top", horizontal: "right" };

  const isAuthenticated = authStatus === "authenticated";

  // console.log("ENVIRONEMENT NOTIFY", process.env.APP_SERVE_MODE);
  return (
    <NotifyContext.Provider value={{ notify }}>
      {children}
      {isAuthenticated ? (
        <Snackbar
          open={notificationDetails.isVisible}
          autoHideDuration={
            isDevelopmentEnv && notificationDetails?.severity === ERROR
              ? 3000
              : 5000
          }
          onClose={() =>
            setNotificationDetails((prev) => {
              return { isVisible: false, message: "", severity: DB_SUCCESS };
            })
          }
          anchorOrigin={
            isDevelopmentEnv && notificationDetails?.severity === ERROR
              ? topCenter
              : topRight
          }
        >
          <Alert
            onClose={() =>
              setNotificationDetails((prev) => {
                return { isVisible: false, message: "", severity: DB_SUCCESS };
              })
            }
            severity={notificationDetails?.severity}
            sx={{ width: "100%" }}
          >
            {isDevelopmentEnv && notificationDetails?.severity === ERROR ? (
              <Typography variant="body1" color="red">
                Error:
                <Typography variant="body1" color="black" component={"span"}>
                  {notificationDetails?.message}
                </Typography>
              </Typography>
            ) : (
              notificationDetails?.message
            )}
          </Alert>
        </Snackbar>
      )
        :
        (
          <Snackbar
            open={notificationDetails.isVisible}
            autoHideDuration={
              isDevelopmentEnv && notificationDetails?.severity === ERROR
                ? 3000
                : 5000
            }
            onClose={() =>
              setNotificationDetails((prev) => {
                return { isVisible: false, message: "", severity: DB_SUCCESS };
              })
            }
            anchorOrigin={
              isDevelopmentEnv && notificationDetails?.severity === ERROR
                ? topCenter
                : topRight
            }
          >
            <Alert
              onClose={() =>
                setNotificationDetails((prev) => {
                  return { isVisible: false, message: "", severity: DB_SUCCESS };
                })
              }
              severity={notificationDetails?.severity}
              sx={{ width: "100%" }}
            >
              {isDevelopmentEnv && notificationDetails?.severity === ERROR ? (
                <Typography variant="body1" color="red">
                  Error:
                  <Typography variant="body1" color="black" component={"span"}>
                    {notificationDetails?.message}
                  </Typography>
                </Typography>
              ) : (
                notificationDetails?.message
              )}
            </Alert>
          </Snackbar>
        )
      }
    </NotifyContext.Provider>
  );
}
export const useNotification = () => useContext(NotifyContext);
