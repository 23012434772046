import React, { useEffect, useState } from "react";
import { useRouter } from "next/navigation";
import AlertModal from "./AlertModal";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import FileUploadOutlined from "@mui/icons-material/FileUploadOutlined";
import { useForm, Controller } from "react-hook-form";
import { useUserData } from "@/app/context/userDetailContext";
import resizeImage from "@/app/utils/ImageResize";
import { API, Storage } from "aws-amplify";
import { useNotification } from "@/app/context/useNotificationContext";
import CircularProgress from "@mui/material/CircularProgress";
import { USERTYPE_USER } from "@/lib/constants"

const REQUIRE_LOGIN = "require login"
const REQUIRE_VERIFICATION = "require verification"
const PENDING_VERIFICATION = "pending verification"
const NO_ID = "no id"

const CommunityCheckPopUp = ({ showModal, setShowModal, setRefresh, verificationStatus, showRequireLoginMessage = false }) => {
    const [openUploadModal, setOpenUploadModal] = useState(false)
    const { userData } = useUserData()
    const router = useRouter();

    return (
        <>
            {showModal &&
                <AlertModal
                    openModal={showModal}
                    closeModal={setShowModal}
                    text={
                        showRequireLoginMessage
                            ? <AlertMessage type={REQUIRE_LOGIN} userType={userData?.userType} />
                            : userData?.verificationStatus === "UNVERIFIED"
                                ? <AlertMessage type={PENDING_VERIFICATION} userType={userData?.userType} />
                                : userData?.verificationStatus === "NO_ID"
                                    ? <AlertMessage type={NO_ID} userType={userData?.userType} />
                                    : <AlertMessage type={REQUIRE_VERIFICATION} userType={userData?.userType} />
                    }
                    buttons={

                        <Stack paddingBottom={2} direction="row" spacing={2}>
                            {userData?.verificationStatus === "UNVERIFIED" ?
                                <Button
                                    onClick={() => {
                                        setOpenUploadModal(false)
                                        setShowModal(false)
                                    }}
                                    sx={{ textTransform: 'none' }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Close
                                </Button>
                                : <></>}
                            {(userData?.verificationStatus === "REQUIRE_LOGIN" || showRequireLoginMessage) ?
                                <Button
                                    onClick={() => {
                                        //setLogin(true)
                                        setShowModal(false)
                                        router.push("/auth");
                                    }}
                                    sx={{ textTransform: 'none' }}
                                    color="primary"
                                    variant="contained"
                                >
                                    Login / Create Account
                                </Button>
                                : <></>}
                            {userData?.verificationStatus === "REQUIRE_VERIFICATION" ?
                                <Button
                                    onClick={() => {
                                        setOpenUploadModal(false)
                                        setShowModal(false)
                                    }}
                                    sx={{ textTransform: 'none' }}
                                    color="primary"
                                    variant="contained"
                                >
                                    {userData?.userType == USERTYPE_USER ? "Upload Villager Id" : "Upload Business Photo"}
                                </Button>
                                : <></>}
                            {userData?.verificationStatus === "NO_ID" ?
                                <Button
                                    onClick={() => {
                                        setOpenUploadModal(true)
                                        setShowModal(false)
                                    }}
                                    sx={{ textTransform: 'none' }}
                                    color="primary"
                                    variant="contained"
                                >
                                    {userData?.userType == USERTYPE_USER ? "Upload Villager Id" : "Upload Business Photo"}
                                </Button>
                                : <></>}
                        </Stack>
                    }
                />}
            {(openUploadModal && !showRequireLoginMessage) && <UploadVillageIdModal openModal={openUploadModal} setOpenModal={setOpenUploadModal} setRefresh={setRefresh} />}
        </>

    );
};

export default CommunityCheckPopUp;


function AlertMessage({ type, userType }) {
    const userMsg = userType == USERTYPE_USER ? "photo of your Villager Id" : "photo representing you and your business"
    const messageText = (type) => {
        switch (type) {
            case REQUIRE_LOGIN:
                return "You need to Login or Sign Up to the site to use this feature";
            case PENDING_VERIFICATION:
                return "Your account or Id is pending verification. Once it has been verified you will be able to fully use CheckMeOut.com."
            case NO_ID:
                return "This function is only available to users who are Photo or Id Verified. Id Verification is FREE! You just need to upload a " + userMsg + ". The photo will be deleted after you are verified."
            case REQUIRE_VERIFICATION:
                return "Your account or ID is not yet verified. Please give us some time to verify your account."
            default:
                return;
        }
    }

    return <Typography>
        {messageText(type)}
    </Typography>
}

const updateMetaDetails = /* GraphQL */ `
  mutation UpdateUserMetaData(
    $input: UpdateUserMetaDataInput!
    $condition: ModelUserMetaDataConditionInput
  ) {
    updateUserMetaData(input: $input, condition: $condition) {
    createdAt
    email
  }
  }`


const UploadVillageIdModal = ({ openModal, setOpenModal, setRefresh }) => {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false)
    const [idImage, setIdImage] = useState("")

    const { userData, userAuthMode } = useUserData()
    const { notify } = useNotification()


    async function updateUserDetails(formData, userAuthMode, ownerId) {
        return API.graphql({
            authMode: userAuthMode,
            query: updateMetaDetails,
            variables: {
                input: {
                    ownerId: ownerId,
                    idImage: formData?.idImage,
                    verificationStatus: "UNVERIFIED"
                }
            },
        });
    }

    const handleClose = () => {
        setOpen(false);
        setOpenModal(false)
        // closeModal(false);
    };

    const uploadIdImage = async (idImageName) => {
        if (idImage) {
            const resizeIdImage = await resizeImage({
                file: idImage,
                fileName: idImageName,
                maxLongestSide: 650
            })
            try {
                const result = await Storage.put(idImageName, resizeIdImage?.image, {
                    level: "public",
                    contentType: resizeIdImage?.type,// contentType is optional
                });
                console.log('File uploaded successfully:', result);
            } catch (error) {
                console.error('Error uploading file:', error);
            }
            setRefresh({ "value": true })
            return
            /*
            return await API.graphql({
                authMode: userAuthMode,
                query: uploadUserIdImage,
                variables: {
                    input: {
                        id: userData?.ownerId,
                        file: resizeIdImage
                    }
                },
            })
            */

        }
    }

    const { handleSubmit, control, reset, getValues } =
        useForm();


    const formSubmit = async () => {

        const formData = getValues();
        const idImageName = `userIdImage/${userData?.ownerId}`
        try {
            setLoading(true)
            await uploadIdImage(idImageName)
            formData.idImage = idImageName
            updateUserDetails(formData, userAuthMode, userData?.ownerId)
            notify({
                type: "success",
                message: "Village Id uploaded!"
            })
            handleClose()
            setRefresh({ value: true })
        } catch (error) {
            console.error("error", error);
            notify({
                type: "error",
                message: "Something went wrong :(",
                error
            })
            reset();
        } finally {
            setLoading(false)
        }

    }

    useEffect(() => {
        if (openModal) setOpen(openModal);
    }, [openModal]);

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth="sm"
        >
            <form onSubmit={handleSubmit(formSubmit)}>
                <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
                <DialogContent>
                    <DialogContentText component={"div"} id="alert-dialog-description">
                        {userData?.userType == USERTYPE_USER ? "Upload Villager Id" : "Upload Business Photo"}
                    </DialogContentText>


                    <Controller
                        name="idImage"
                        control={control}
                        defaultValue={""}
                        render={({
                            field: { onChange, onBlur, value },
                            fieldState: { error },
                        }) => {
                            return (
                                <TextField
                                    margin="normal"
                                    disabled
                                    fullWidth
                                    required
                                    value={idImage?.name || ""}
                                    onChange={(e) => {
                                        setIdImage(event.target.files[0])
                                        onChange(e)
                                    }}
                                    placeholder={userData?.userType == USERTYPE_USER ? "Village ID image" : "Business Photo"}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">

                                                <Button
                                                    size="small"
                                                    sx={{
                                                        alignSelf: "center"
                                                    }}
                                                    component="label"
                                                    variant="outlined"
                                                    startIcon={<FileUploadOutlined />}
                                                    onChange={(event) => {
                                                        // console.log("Event on image change", event.target.files[0], event)
                                                        setIdImage(event.target.files[0])
                                                    }}
                                                >
                                                    {userData?.userType == USERTYPE_USER ? "Upload Villager Id" : "Upload Business Photo"}

                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        hidden
                                                    />
                                                </Button>


                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )
                        }}
                    />


                </DialogContent>
                <DialogActions sx={{ justifyContent: "end" }}>
                    {loading && <Box position={'absolute'} top={'50%'} left={'45%'}> <CircularProgress color="secondary" /></Box>}
                    <Button
                        variant="contained"
                        sx={{
                            borderRadius: '33px',
                            backgroundColor: '#676767',
                            color: '#d9d9d9',
                            fontSize: '16px',
                            fontWeight: '400',
                        }}
                        disabled={loading}
                        onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        sx={{
                            borderRadius: '33px',
                            backgroundColor: '#359AE3',
                            color: 'white',
                            fontSize: '16px',
                            fontWeight: '800',
                        }}
                        disabled={!idImage || loading}>
                        Upload
                    </Button>

                </DialogActions>
            </form>
        </Dialog>
    )
}
