import Resizer from "react-image-file-resizer"
import Pica from "pica"

const base64ToImageFile = (base64Data, filename) => {
    const base64WithoutPrefix = base64Data.replace(/^data:image\/(png|jpeg|jpg);base64,/, '');

    const binaryString = window.atob(base64WithoutPrefix);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }

    // Create a Blob with the image data
    const blob = new Blob([uint8Array], { type: 'image/jpeg' }); // Change 'image/jpeg' to 'image/png' if it's a PNG image

    // Create a File from the Blob (optional, if you need a File object)
    const imageFile = new File([blob], filename, { type: 'image/jpeg' }); // Change 'image/jpeg' to 'image/png' if it's a PNG image
    const imageDimensionsPromise = new Promise((resolve, reject) => {
        const imageElement = new Image();
        imageElement.onload = () => {
            const dimensions = {
                width: imageElement.naturalWidth,
                height: imageElement.naturalHeight,
            };
            resolve(dimensions);
        };
        imageElement.onerror = (error) => {
            reject(error);
        };
        imageElement.src = URL.createObjectURL(blob);
    });
    return { blob, imageFile, imageDimensions: imageDimensionsPromise };
};


export default async function resizeImage({ file, fileName, maxLongestSide }) {
    return new Promise((resolve, reject) => {
        Resizer.imageFileResizer(
            file,
            maxLongestSide,
            maxLongestSide,
            'JPEG',
            90,
            0,
            (uri) => {
                const newFile = new File([uri], fileName, { type: 'image/jpeg' });
                resolve({ image: newFile, fileName, type: 'image/jpeg' });
            },
            'blob'
        );
    });
}

export async function makeSquareImage({ file, fileName, width }) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
            const img = new Image();
            img.src = event.target.result;
            img.onload = async () => {
                const size = Math.min(img.width, img.height);
                const x = (img.width - size) / 2;
                const y = (img.height - size) / 2;

                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                canvas.width = size;
                canvas.height = size;

                // Draw the cropped image on the canvas
                ctx.drawImage(img, x, y, size, size, 0, 0, size, size);

                const outputCanvas = document.createElement('canvas');
                outputCanvas.width = width;
                outputCanvas.height = width;

                const pica = Pica();
                try {
                    await pica.resize(canvas, outputCanvas);
                    outputCanvas.toBlob((blob) => {
                        const newFile = new File([blob], fileName, { type: 'image/jpeg' });
                        resolve({ image: newFile, fileName, type: 'image/jpeg' });
                    }, 'image/jpeg');
                } catch (error) {
                    reject(error);
                }
            };
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
    });
};

/*
export default async function resizeImage({ file, fileName, maxWidth, maxHeight, imageFormat = "JPEG" }) {
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                imageFormat,
                100,
                0,
                (uri) => {
                    // const base64str = uri.split("base64,")[1];
                    resolve(uri);
                },
            );
        });
    const imageFile = await resizeFile(file)

    const { imageFile: image, imageDimensions } = base64ToImageFile(imageFile, fileName)

    imageDimensions.then(res => console.log("Uploaded , IMAGE DIMENSION", res))
    const type = file?.type;
    return { image, type };
}
*/

export async function resizeImageWithBase64({ file, fileName, maxWidth, maxHeight, imageFormat = "JPEG" }) {
    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                maxWidth,
                maxHeight,
                imageFormat,
                100,
                0,
                (uri) => {
                    // const base64str = uri.split("base64,")[1];
                    resolve(uri);
                },
            );
        });

    const imageFile = await resizeFile(file)
    return imageFile;
}