/**
 * The URL for the API authentication route
 * @type {string}
 */
export const URL_LOGIN = "/auth/login"

/**
 * The URL to log out the current user
 * @type {string}
 */
export const URL_LOGOUT = "/auth/logout"

/** 
 * The URL to the image bucket
 * @type {string}
 */
export const URL_IMAGE = "https://cmoimg.com"

/**
 * The URL to go to after a successful login
 * @type {string}
 */
//export const URL_LOGIN_REDIRECT = "/auth/login/successful"
export const URL_LOGIN_REDIRECT = "/auth/login/status"

/**
 * The string used if the user is a user
 * @type {string}
 */
export const USERTYPE_USER = "user"

/**
 * The string used if the user is an administrator
 * @type {string}
 */
export const USERTYPE_ADMINISTRATOR = "administrator"

/**
 * The string used if the user is a guest
 * @type {string}
 */
export const USERTYPE_GUEST = "guest"

/**
 * The string used if the user is a moderator
 * @type {string}
 */
export const USERTYPE_MODERATOR = "moderator"

/**
 * The user type for a business
 * @type {string}
 */
export const USERTYPE_BUSINESS = "business"

/**
 * The fallback image for a business
 * @type {string}
 */
export const fallbackImg = "/business_default.jpg"

/**
 * The pagination limit for a list
 * @type {number}
 */
export const paginationLimit = 12

/**
 * The region for the AWS bucket
 * @type {string}
 */
export const REGION = "us-east-1"

/**
 * The review type for a review
 * @type {string}
 */
export const REVIEW_TYPE = "review"

/**
 * The string used in the session.status if the session is loading
 * @type {string}
 */
export const LOADING = "loading"

/**
 * The string used in the session.status if the user has been authenticated
 * @type {string}
 */
export const AUTHENTICATED = "authenticated"

/**
 * The string used in the session.status if the user has not been authenticated
 * @type {string}
 */
export const UNAUTHENTICATED = "unauthenticated"

/**
 * The string used if the user is an admin
 * @type {string}
 */
export const ADMIN = "Admin";

/**
 * The string used if the user is a moderator
 * @type {string}
 */
export const MODERATOR = "Moderator"

/**
 * The string used if the user was just created
 * @type {string}
 */
export const VERIFICATION_STATUS_NEW_USER = "newuser"

/**
 * The string used if the user has not uploaded an id
 * @type {string}
 */
export const VERIFICATION_STATUS_NOID = "noid"

/**
 * The string used if the user is not verified
 * @type {string}
 */
export const VERIFICATION_STATUS_NOT_VERIFIED = "notverified"

/**
 * The string used if the user is verified
 * @type {string}
 */
export const VERIFICATION_STATUS_VERIFIED = "verified"

/**
 * The string used if the database query failed
 * @type {string}
 */
export const DB_ERROR = "dberror"

/**
 * The string used if the database query succeeded
 * @type {string}
 */
export const DB_SUCCESS = "success"

/**
 * The string used if the database query warning
 * @type {string}
 */
export const DB_WARNING = "warning"

/**
 * The string used if the database query error
 * @type {string}
 */
export const ERROR = "error"

/**
 * The folder for the community image
 * @type {string}
 */
export const FOLDER_COMMUNITY = "communities"

/**
 * The folder for the avatar image
 * @type {string}
 */
export const FOLDER_AVATAR = `avatars`

/**
 * The folder for the ids
 * @type {string}
 */
export const FOLDER_IDS = `ids`

/**
 * The folder for the business image
 * @type {string}
 */
export const FOLDER_BUSINESS = `businesses`

/**
 * The folder for the images related to a business
 * @type {string}
 */
export const FOLDER_IMAGES = `${FOLDER_BUSINESS}/images`

